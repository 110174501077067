.learning-objective-mark__field {
	display: flex;
	flex-direction: row-reverse;
}

.learning-objective-mark__field > :nth-child(1) {
	width: 3rem;
}

/* TODO remove when noLabel option added to fields */
.learning-objective-mark__field > :nth-child(1) > :nth-child(1) {
	display: none;
}

.learning-objective-mark__field > :nth-child(2) {
	flex-wrap: nowrap;
}
