html {
	--placeholder: 0;

	--shadow-offset: 0.375rem;
	--general__tile---box-shadow: var(--shadow-offset) var(--shadow-offset) calc(2 * var(--shadow-offset)) #00000026,
		calc(-1 * var(--shadow-offset)) calc(-1 * var(--shadow-offset)) calc(2 * var(--shadow-offset)) #ffffff4c;
}

body,
main {
	background-color: #fff !important;
}

.styles_header__bar__QAAC\+,
.header__bar__KSXAj8BY,
.styles_tile__6jFKY,
.tile__oWIAT00G {
	--shadow-offset: 0.375rem;
	box-shadow: var(--shadow-offset) var(--shadow-offset) calc(2 * var(--shadow-offset)) #00000026,
		calc(-1 * var(--shadow-offset)) calc(-1 * var(--shadow-offset)) calc(2 * var(--shadow-offset)) #ffffff4c;
}

.styles_header__bar__QAAC\+,
.header__bar__KSXAj8BY {
	--shadow-offset: 0.2rem;
}

main {
	padding-bottom: 0.75rem !important;
}

footer {
	display: none;
}
