/* Current Course */

.current-course-tile__container-link {
	--internal-link---text-decoration: none;
	--internal-link--hover-focus---text-decoration: none;

	transition: 100ms ease-out;
}

.current-course-tile__container-link:hover {
	transform: translateY(-2px);
}

.current-course-tile {
	--section__body---padding: 0.75rem;
	overflow: hidden;
	transform: translateY(0); /* needed to keep overflow hidden (for progress bar) */
}

.current-course-tile__course-name {
	text-align: center;
	font-family: "Fredoka One", sans-serif;
	font-size: 1.75rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
	display: inline-block;
}

.current-course-tile:hover .current-course-tile__time {
	display: none;
}

.current-course-tile:not(:hover) .current-course-tile__hover-text {
	display: none;
}
