.header-tile {
	position: relative;
	--section__body---padding: 1.25rem 1.5rem;
}

/* TODO copied from CoursePeriod/styles.module.css */

.header-tile__link {
	font-size: 1.5rem;
	text-decoration: none;
	display: inline-flex;
	align-items: center;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.header-tile__link--back {
	left: 1rem;
}

.header-tile__link--forward {
	right: 1rem;
}

.header-tile__link--back::before,
.header-tile__link--forward::after {
	content: "";
	--size: 1.5rem;
	width: var(--size);
	height: var(--size);
	border: 5px solid var(--verita-blue);
	border-radius: 5px;
	display: inline-block;
	transform: rotate(0.125turn);
}

.header-tile__link--back::before {
	border-top: none;
	border-right: none;
}

.header-tile__link--forward::after {
	border-bottom: none;
	border-left: none;
}
