.attendance-status {
	font-size: 0.9rem;
	white-space: nowrap;
	color: #fff;

	width: fit-content;
	padding: 0.15rem 0.5rem;
	border-radius: 0.5rem;
}

.attendance-status--success {
	background-color: var(--success-color);
}

.attendance-status--failure {
	background-color: var(--danger-color);
}
