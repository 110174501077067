.floating-submit-button-container {
	width: fit-content;
	border-radius: var(--button---border-radius);
	box-shadow: var(--general---box-shadow);
	margin-left: auto;

	position: sticky;
	bottom: 0;
	z-index: 10;
}
