.get-started-button__icon {
	transition: 150ms ease-out;
}

.get-started-button:hover .get-started-button__icon {
	transform: translate(5px);
}

.lesson-plans-main {
	--stripe-height: 15rem;
	background-image: linear-gradient(transparent 0 calc(100% - var(--stripe-height)), #0063ad20 0 100%);
}

.leslie-icon {
	padding: 0.5rem;
}

.leslie-icon > * > * {
	box-shadow: var(--general---box-shadow);
}

.lesson-plans-container {
	flex-grow: 1;
	padding: 1.5rem 1.5rem;
}

.lesson-plan-item {
	background-color: #fff;

	border: 1px solid var(--verita-blue);
	border-radius: var(--general__tile---border-radius);
}

.lesson-plan-item > :first-child {
	border-top-left-radius: var(--general__tile---border-radius);
	border-top-right-radius: var(--general__tile---border-radius);
}

.lesson-plan-item > :last-child {
	border-bottom-left-radius: var(--general__tile---border-radius);
	border-bottom-right-radius: var(--general__tile---border-radius);
}

.lesson-plan__day-icon {
	--size: 2.5rem;
	width: var(--size);
	height: var(--size);
	border: 1px solid var(--verita-blue);
	border-radius: 50%;

	position: relative;
}

.lesson-plan__day-icon__text {
	font-size: calc(var(--size) / 1.75);

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.check-icon {
	background-color: var(--success-color);

	--size: 1rem;
	width: var(--size);
	height: var(--size);
	border-radius: 50%;

	position: relative;
}

.check-icon__icon {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.lesson-plan__day-icon__check-icon {
	position: absolute;
	bottom: calc(-1 * var(--size) / 4);
	left: calc(-1 * var(--size) / 4);
}
