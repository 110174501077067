.document-resources-tile {
	position: relative;
	background-color: transparent;
}

.document-resources-tile__body {
	padding: 0;
}

.document-resources-tile__content {
	background-color: var(--background-color);

	padding: var(--section__body---padding);
	border-radius: var(--general__tile---border-radius);

	position: relative;
	z-index: 2;
}

.document-resources-tile__expand-button {
	color: var(--verita-blue);
	background-color: var(--background-color);

	--exposed-height: 2rem;
	--additional-height: 2.5rem;

	width: 100%;
	height: calc(var(--exposed-height) + var(--additional-height));
	padding: 0;
	padding-bottom: var(--additional-height);
	border: none;
	border-top-right-radius: var(--general__tile---border-radius);
	border-top-left-radius: var(--general__tile---border-radius);

	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;

	transition: 200ms ease-out;
}

.document-resources-tile:hover .document-resources-tile__expand-button {
	transform: translateY(-2rem);
	box-shadow: var(--general__tile---box-shadow);
}
