.student-tile__list {
	padding: 0;
}

.student-tile__list__item:not(:last-child) {
	border-bottom: 1px solid #eee;
}

.student-tile__list__item__row {
	padding: 0.25rem;
	padding-right: 2rem;

	position: relative;
}

.student-tile__list__item__action-buttons-row {
	background-color: #fffb;

	padding: 0 0.25rem;

	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.student-tile__list__item:not(:hover) .student-tile__list__item__action-buttons-row {
	opacity: 0;
	pointer-events: none;
}

.student-tile__list__item__action-buttons-row button {
	background-color: #fff;
}

/* Attendance */

.attendance-form__student-item {
	padding: 0.75rem;
	border-radius: 1rem;
	position: relative;
	transition: 150ms ease-out;

	--status-color: #fff;
	border: 1px solid var(--status-color);
	/* background-image: linear-gradient(var(--status-color) 0 3rem, transparent 0 100%); */
}

.attendance-form__student-item:hover {
	box-shadow: var(--general---box-shadow);
	transform: translateY(-2px);
}

.attendance-form__student-item--present {
	--status-color: var(--verita-blue);
}

.attendance-form__student-item--absent {
	--status-color: var(--danger-color);
}

.attendance-form__student-item__presence-status,
.attendance-form__student-item__late-status {
	position: absolute;
	top: 0.75rem;
}

.attendance-form__student-item__presence-status {
	/* color: #fff; */
	left: 0.5rem;
}

.attendance-form__student-item--absent .attendance-form__student-item__presence-status {
	color: var(--status-color);
}

.attendance-form__student-item__late-status {
	color: var(--warning-color);
	right: 0.5rem;
}
