.schedule-link {
	--internal-link---text-decoration: none;
}

.schedule-link__icon {
	transition: 150ms ease-out;
}

.schedule-link:hover .schedule-link__icon {
	transform: translateX(4px);
}

/* TODO variables for animation timing */

.course-item {
	color: #fff;
	background-color: var(--course-theme-color);

	flex-shrink: 0;
	width: 9rem;
	height: 9rem;
	padding: 0.5rem 0.5rem 0.25rem;
	border-radius: 0.5rem;
	overflow: hidden;

	position: relative;

	transition: 150ms ease-out;
}

.course-item:hover {
	box-shadow: 2px 4px 8px var(--general---box-shadow-color), 0px 0px 8px var(--general---box-shadow-color);
	transform: translateY(-4px);
}

.course-item__title-placeholder,
.course-item__title {
	font-family: "Fredoka One", sans-serif;
	font-size: 1.1rem;
	text-align: left;
	width: 100%;
}

.course-item__title-placeholder {
	opacity: 0;
}

.course-item__title {
	font-size: 1.5rem;

	padding: 0.5rem;

	position: absolute;
	top: 0;
	left: 0;

	transition: 100ms ease-out;
}

.course-item:hover .course-item__title {
	font-size: 1.1rem;
}

.course-item__lesson-description {
	width: 100%;
	height: 100%;
	padding: 0 0.5rem;
	flex-grow: 1;
	overflow: hidden;
	position: relative;

	transition: 200ms ease-out 50ms;
}

.course-item:not(:hover) .course-item__lesson-description {
	opacity: 0;
}

.course-item__lesson-description__overflow-fade {
	background-image: linear-gradient(transparent, var(--course-theme-color));

	width: 100%;
	height: 1.5rem;

	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 5;
}

.course-item__bottom-content {
	width: 100%;

	transition: 175ms ease-out;
}

.course-item:hover .course-item__bottom-content {
	opacity: 0;
	transition-delay: 0;
}

.course-item:not(:hover) .course-item__bottom-content {
	transition-delay: 150ms;
}

.course-item__time {
	font-size: 1.8rem;
}

.course-item__action-link {
	--internal-link---color: var(--course-theme-color);
	--internal-link--hover-focus---color: var(--course-theme-color);
	text-align: center;
	background-color: #fff;
	--internal-link---text-decoration: none;
	white-space: nowrap;

	width: fit-content;
	padding: 0.25rem 0.75rem;
	border-radius: 5rem;

	display: inline-block;

	position: absolute;
	--bottom: 0.5rem;
	bottom: var(--bottom);
	left: 50%;
	z-index: 10;

	transform: translate(-50%, 0);

	transition: 100ms ease-out;
}

.course-item:hover .course-item__action-link {
	transition-delay: 100ms;
}

.course-item:not(:hover) .course-item__action-link {
	transform: translate(-50%, calc(100% + var(--bottom)));
	transition-delay: 0;
}

.course-item__lesson-plan-link {
	--lesson-plan-bottom: calc(var(--bottom) + 2rem);
	bottom: var(--lesson-plan-bottom);
	transform: translate(-50%, 0);
}

.course-item:not(:hover) .course-item__lesson-plan-link {
	transform: translate(-50%, calc(100% + var(--lesson-plan-bottom)));
}

.course-item__action-link__icon {
	transition: 150ms ease-out;
}

.course-item__action-link:hover .course-item__action-link__icon {
	transform: translateX(3px);
}
