.main-tile {
	--field---font-size: 1.25rem;
}

.label {
	font-size: 1.1rem;
	font-weight: bold;
}

.field {
	font-size: var(--field---font-size);
}

/* Learning Objectives */

.learning-objective {
	position: relative;
}

.learning-objective--multiple {
	padding-left: 1.25rem;
}

.learning-objective__number {
	color: #0063ad30;

	--size: 1rem;
	width: var(--size);
	height: var(--size);
	border: 1px solid #0063ad30;
	border-radius: 50%;

	position: absolute;
	top: 1.25rem;
	left: 0;
}

.learning-objective__number__content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.learning-objective__category {
	color: #666;
	font-size: 0.8rem;
}

/* Stations */

.station-description {
	width: calc(1 / 3 * (100% - 2 * 0.75rem));
	padding: 2.5rem 1rem;
	border: 1px solid #0063ad30;
	border-radius: var(--general__tile---border-radius);

	position: relative;
}

.station-description .field {
	font-size: 1.5rem;
}

.station-description__number {
	font-size: 2rem;
	font-family: "Fredoka One", sans-serif;
	color: var(--verita-blue);
	opacity: 0.2;

	position: absolute;
	top: 0.25rem;
	left: 1.25rem;
}
