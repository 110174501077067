.header-tile__lesson-plans-row {
	flex-grow: 1;
}

.header-tile__lesson-plan {
	width: 20%;
	padding: 0 0.25rem;
}

.header-tile__lesson-plan .header-tile__lesson-plan__heading {
	color: var(--general---color);
	font-weight: bold;
	--internal-link---text-decoration: none;
	text-align: center;

	width: 100%;
	padding: 0.25rem 0;
	border: 2px solid #ccc;
	border-radius: 5rem;
}

.header-tile__lesson-plan--active .header-tile__lesson-plan__heading {
	color: var(--verita-blue);
	border-color: var(--verita-blue);
}

.header-tile__lesson-plan .header-tile__lesson-plan__heading:hover {
	background-color: #f2f2f2;
}

.header-tile__lesson-plan__description {
	height: 2.5rem;
	position: relative;
	overflow: hidden;
}

.header-tile__lesson-plan__description__cutoff {
	background-image: linear-gradient(transparent 80%, #fff);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.header-tile__lesson-plan__buttons-row {
	width: 100%;
	padding: 0 0.5rem;
}

.text-area-display {
	font-family: var(--general---font-family);

	white-space: pre-wrap; /* css-3 */
	white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
	white-space: -pre-wrap; /* Opera 4-6 */
	white-space: -o-pre-wrap; /* Opera 7 */
	word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.index-blur-overlay,
.next-overlay {
	backdrop-filter: blur(12px) saturate(60%);
	width: calc(100% + 1rem);
	height: calc(100% + 1rem);
	position: absolute;
	top: -0.5rem;
	left: -0.5rem;
	z-index: 20;
}

.index-blur-overlay__content,
.next-overlay__content {
	font-size: 2rem;
	padding: 1rem 1.5rem;
	background-color: #fffb;
	border-radius: var(--general__tile---border-radius);
}
