.collapsible {
	position: relative;
	--button-content-spacing: 0px;
}

.collapsible--expanded .collapsible__button {
	margin-bottom: var(--button-content-spacing);
}

.collapsible--with-absolute-position .collapsible__button {
	margin-bottom: 0;
}

.collapsible--collapsed .collapsible__content {
	opacity: 0;
	height: 0;
	overflow: hidden;
	pointer-events: none;
	visibility: hidden;
}

.collapsible--with-absolute-position .collapsible__content {
	position: absolute;
	top: calc(100% + var(--button-content-spacing));
	left: 0;
}

.collapsible--collapsed.collapsible--with-absolute-position .collapsible__content {
	pointer-events: none;
	height: auto;
}
